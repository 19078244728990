import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const PrivacyPage = () => {
  return (
    <Layout>
      <Seo title="プライバシーポリシー" />
      <div className="flex justify-center items-center p-5 md:p-20 bg-gray-50">
        <h1 className="sm:text-3xl text-2xl font-medium text-gray-600">
          プライバシーポリシー
          <br />
          （個人情報保護方針）
        </h1>
      </div>
      <div className="container mx-auto max-w-4xl p-5 md:p-10 leading-8 md:leading-10 text-base md:text-lg">
        <p>
          鈴木会計事務所（鈴木正治税理士事務所・鈴木正治行政書士事務所・中央労務管理事務所）は（以下「当事務所」という）、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。
        </p>
        <h2 className="text-xl py-5 md:py-10">１.個人情報の安全管理</h2>
        <p>
          当事務所は、個人情報の保護に関して、組織的、物理的、人的、技術的に適切な対策を実施し、当社の取り扱う個人情報の漏えい、滅失又はき損の防止その他の個人情報の安全管理のために必要かつ適切な措置を講ずるものとします。
        </p>
        <h2 className="text-xl py-5 md:py-10">２.個人情報の取得等の遵守事項</h2>
        <p>
          当事務所による個人情報の取得、利用、提供については、以下の事項を遵守します。
        </p>
        <h3 className="py-2 md:py-5">(1)個人情報の取得</h3>
        当事務所は、当事務所が管理するインターネットによる情報提供サイト（以下「本サイト」といいます。）の運営に必要な範囲で、本サイトの一般利用者（以下「ユーザー」といいます。）又は本サイトに広告掲載を行う者（以下「掲載主」といいます。）から、ユーザー又は掲載主に係る個人情報を取得することがあります。
        <h3 className="py-2 md:py-5">(2)個人情報の利用目的</h3>
        当事務所は、当事務所が取得した個人情報について、法令に定める場合又は本人の同意を得た場合を除き、以下に定める利用目的の達成に必要な範囲を超えて利用することはありません。
        <ol className="list-disc list-outside pl-6">
          <li>本サイトの運営、維持、管理</li>
          <li>本サイトを通じたサービスの提供及び紹介</li>
          <li>本サイトの品質向上のためのアンケート</li>
        </ol>
        <h3 className="py-2 md:py-5">(3)個人情報の提供等</h3>
        当事務所は、法令で定める場合を除き、本人の同意に基づき取得した個人情報を、本人の事前の同意なく第三者に提供することはありません。なお、本人の求めによる個人情報の開示、訂正、追加若しくは削除又は利用目的の通知については、法令に従いこれを行うとともに、ご意見、ご相談に関して適切に対応します。
        <h2 className="text-xl py-5 md:py-10">３.個人情報の利用目的の変更</h2>
        当事務所は、前項で特定した利用目的は、予め本人の同意を得た場合を除くほかは、原則として変更しません。但し、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲において、予め変更後の利用目的を公表の上で変更を行う場合はこの限りではありません。
        <h2 className="text-xl py-5 md:py-10">４.個人情報の第三者提供</h2>
        当事務所は、個人情報の取扱いの全部又は一部を第三者に委託する場合、その適格性を十分に審査し、その取扱いを委託された個人情報の安全管理が図られるよう、委託を受けた者に対する必要かつ適切な監督を行うこととします。
        <h2 className="text-xl py-5 md:py-10">
          ５.個人情報の取扱いの改善・見直し
        </h2>
        当事務所は、個人情報の取扱い、管理体制及び取組みに関する点検を実施し、継続的に改善・見直しを行います。
        <h2 className="text-xl py-5 md:py-10">６.個人情報の廃棄</h2>
        当事務所は、個人情報の利用目的に照らしその必要性が失われたときまたは開示・停止・消去の請求があった場合、個人情報を消去又は廃棄するものとし、当該消去及び廃棄は、外部流失等の危険を防止するために必要かつ適切な方法により、業務の遂行上必要な限りにおいて行います。
        <hr className="my-5 md:my-10" />
        <div className="flex flex-col text-center">
          <p>
            本ポリシーに関してのお問合せはこちらへ
            <br />
            Tel 027-243-4881
            <br />
            Fax 027-243-4987
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPage;
